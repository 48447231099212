import React from 'react';
import { Moment } from 'moment';
import get from 'lodash/get';
import DateRangePicker from 'components/input/DateRangePicker';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { Brick } from 'components/bricks/types/brick.type';

import './../styles/date-range-input.scss';

interface Props {
    title: string;
    brick: Brick;
    modelStart: string;
    modelEnd: string;
}

interface DateRangeValue {
    [key: string]: Moment | null;
}

const DateRangeInput: React.FC<Props> = ({ title, brick, modelStart, modelEnd }) => {
    const fieldFrom = 'dateStart';
    const fieldTo = 'dateEnd';

    const handleDateChange = (value: DateRangeValue | null) => {
        if (value === null) {
            BricksComponentStoreHelper.setBrickModel(brick.id, modelStart, undefined);
            BricksComponentStoreHelper.setBrickModel(brick.id, modelEnd, undefined);
            return;
        }

        if (value[fieldFrom]) {
            BricksComponentStoreHelper.setBrickModel(brick.id, modelStart, value[fieldFrom]);
        }
        if (value[fieldTo]) {
            BricksComponentStoreHelper.setBrickModel(brick.id, modelEnd, value[fieldTo]);
        }
    };

    const dateStart = get(brick, modelStart);
    const dateEnd = get(brick, modelEnd);

    return (
        <div className="briefing-content-date-range-input">
            <span className="briefing-content-date-range-input__title">{title}</span>
            <DateRangePicker onMutation={handleDateChange} fieldFrom={fieldFrom} fieldTo={fieldTo} calendarPosition="top" value={{ dateStart, dateEnd }} />
        </div>
    );
};

export default DateRangeInput;
