import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import set from 'lodash/set';
import { MODEL_AD_SETUP } from 'components/bricks/constants';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import useBrick from 'components/bricks/hooks/useBrick';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import cloneDeep from 'helpers/cloneDeep';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { Brick } from 'components/bricks/types/brick.type';
import { AdSetup } from '../types/AdSetup.type';

interface AdSetupContextType {
    adSetup: AdSetup;
    selectedFrame: number;
    creatives?: CreativeV2[];
    loaded: boolean;
    updateAdSetupFull: (newAdSetup: AdSetup) => void;
    updateAdSetupProperty: (property: string, value: unknown, isItem?: boolean) => void;
    updateSelectedFrame: (value: number) => void;
    updateFrameCreatives: (frameCreatives: CreativeV2[]) => void;
}

interface Props {
    children: ReactNode;
}

const AdSetupContext = createContext<AdSetupContextType>({
    adSetup: {},
    selectedFrame: 0,
    creatives: undefined,
    loaded: false,
    updateAdSetupFull: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateAdSetupProperty: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateSelectedFrame: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateFrameCreatives: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
});
AdSetupContext.displayName = 'AdSetupContext';

const useAdSetupContext = (): AdSetupContextType => {
    return useContext(AdSetupContext);
};

/**
 * Custom hook for ad setup
 */
const AdSetupProvider = ({ children }: Props) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [frameCreatives, setFrameCreatives] = useState<CreativeV2[]>([]);
    const [selectedFrame, setSelectedFrame] = useState<number>(0);

    const { brick } = useBrick();

    useEffect(() => {
        const loadCreatives = async () => {
            setLoaded(false);
            const brickCreatives: CreativeV2[] = cloneDeep(brick?.data?.creatives);
            if (!brickCreatives) {
                setLoaded(true);
                setFrameCreatives([]);
                return;
            }

            const frameCreativeIds = brick?.data?.adSetup?.items?.[selectedFrame]?.creativeIds;
            const filteredCreatives = brickCreatives.filter((creative) => frameCreativeIds?.includes(creative.id));

            await Promise.all(filteredCreatives.map((creative) => CreativeV2Helpers.loadTemplateForCreative(creative)));

            setFrameCreatives(filteredCreatives);
            setLoaded(true);
        };

        loadCreatives();
    }, [selectedFrame, frameCreatives?.length]);

    // We use this function to update the frame creatives manually (e.g. in ad-setup-type handleSelectCreatives)
    const updateFrameCreatives = (frameCreatives: CreativeV2[]): void => {
        setFrameCreatives(frameCreatives);
    };

    if (!brick) return;

    const validateDebounce = (newBrick: Brick) => {
        ValidateHelpers.validate(newBrick);
    };

    const updateAdSetupFull = (newAdSetup: AdSetup): void => {
        const brickCopy: Brick = cloneDeep(brick);
        set(brickCopy, MODEL_AD_SETUP, newAdSetup);
        validateDebounce(brickCopy);
        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_AD_SETUP, newAdSetup);
    };

    const updateSelectedFrame = (value: number): void => {
        setSelectedFrame(value);
    };

    /**
     * Handles on change of property
     * @param path The path to the property that is being changed
     * @param value The new value for the property
     * @param isItem If the property is an item, it sets the value in the selected frame in the items array. If not, it sets the value in the root
     */
    const updateAdSetupProperty = (path: string, value: unknown, isItem = false): void => {
        const adSetupCopy = cloneDeep(brick.data?.adSetup);
        const finalPath = isItem ? `items.${selectedFrame}.${path}` : path;
        set(adSetupCopy, finalPath, value);
        updateAdSetupFull(adSetupCopy);
    };

    const contextValue = {
        adSetup: brick.data?.adSetup || {},
        selectedFrame,
        creatives: frameCreatives,
        loaded,
        updateAdSetupFull,
        updateAdSetupProperty,
        updateSelectedFrame,
        updateFrameCreatives
    };

    return <AdSetupContext.Provider value={contextValue}>{children}</AdSetupContext.Provider>;
};

export default AdSetupProvider;
export { useAdSetupContext, AdSetupContext, AdSetupProvider };
