import React, { memo } from 'react';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import { MODEL_DEADLINE, MODEL_END_DATE, MODEL_ROLES_ASSIGNEES, MODEL_ROLES_REVIEWERS, MODEL_START_DATE } from 'components/bricks/constants';
import useBrick from 'components/bricks/hooks/useBrick';
import Accordion from './../../accordion';
import UserInput from './user-input';
import DateInput from './date-input';
import DateRangeInput from './date-range-input';
import { BriefingAttachments } from './attachments';
import { BriefingDescription } from './description';
import { BriefingTitle } from './title';
import './../styles/main.scss';

export interface BriefingProps {
    /** Determines if margin show be enabled or not. */
    noMargin?: boolean;
    /** Determines if the title should be shown. */
    showTitle?: boolean;
    /** If we request a brick, the briefing component uses the temporary brick in the store */
    isTemporaryBrick?: boolean;
}

/** Briefing content component for showing and editing briefing details. */
const DefaultBriefingContent = memo(({ noMargin, showTitle = false, isTemporaryBrick = false }: BriefingProps) => {
    const { brick } = useBrick(undefined, isTemporaryBrick); // Get the brick from the hook if not provided as a prop.

    if (!brick) return null;

    return (
        <div className={classNames('briefing-content', { 'briefing-content--noMargin': noMargin })}>
            <Accordion title={Translation.get('labels.briefing', 'common')} classes={{ root: 'briefing-content__accordion' }}>
                <>
                    {showTitle && <BriefingTitle brick={brick} />}
                    <BriefingDescription brick={brick} />
                    <div className="briefing-content__attachments">
                        <span className="briefing-content__attachments__title">{Translation.get('labels.attachments', 'common')}</span>
                        <BriefingAttachments brick={brick} />
                    </div>
                    <div className="briefing-content__details">
                        <UserInput
                            selectedUserId={brick?.data?.briefing?.roles?.assignees?.[0]}
                            title={Translation.get('assignee', 'bricks')}
                            model={MODEL_ROLES_ASSIGNEES}
                            placeholder={Translation.get('noAssignee', 'bricks')}
                            brickId={brick.id}
                        />
                        <DateInput
                            title={Translation.get('deadline', 'bricks')}
                            date={brick?.metadata?.dateDeadline}
                            model={MODEL_DEADLINE}
                            brickId={brick.id}
                        />
                        <DateRangeInput
                            title={Translation.get('startEndDate', 'bricks')}
                            brick={brick}
                            modelStart={MODEL_START_DATE}
                            modelEnd={MODEL_END_DATE}
                        />
                        <UserInput
                            selectedUserId={brick?.data?.briefing?.roles?.reviewers?.[0]}
                            title={Translation.get('reviewer', 'bricks')}
                            model={MODEL_ROLES_REVIEWERS}
                            placeholder={Translation.get('noReviewer', 'bricks')}
                            brickId={brick.id}
                        />
                    </div>
                </>
            </Accordion>
        </div>
    );
});

DefaultBriefingContent.displayName = 'DefaultBriefingContent';

export default DefaultBriefingContent;
