import React from 'react';
import CreativeOverview, { CreativeOverviewProps } from 'components/creatives-v2/components/creative-overview';
import { CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { Brick } from 'components/bricks/types/brick.type';
import MultiInputWrapperHelpers from '../bricks-multi-input-block-wrapper/helpers';

interface Props extends CreativeOverviewProps {
    brick: Brick;
}

/**
 * This wrapper component is used to add bricks specific logic to the creative overview component.
 */
const BricksCreativeOverview: React.FC<Props> = ({ brick, ...props }) => {
    const onSaveCreative = (creative: CreativeV2Template) => {
        props.onSaveCreative?.(creative);
        // Make sure the active formats are updated in the brick component store.
        MultiInputWrapperHelpers.handleOnActiveFormatChange(brick, creative.data.settings.activeFormats ?? []);
    };

    return <CreativeOverview {...props} onSaveCreative={onSaveCreative} />;
};

export default BricksCreativeOverview;
